/** @jsx jsx **/
// @ts-ignore
import { jsx, Container, Box, BaseStyles, Heading, Text } from 'theme-ui'
import Layout from '~/components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import MarkdownIt from '~/components/MarkdownIt'
import theme from '~/gatsby-plugin-theme-ui'
import { GatsbyImageType } from '~/@types/models'
import HeroImage from '~/components/hero-image'
import LaShowroomOpenings from '~/containers/la-showroom-openings'
import HomepageHeader from '~/components/homepage/header'
import HeadingV2 from '~/componentsV2/heading'
import { ShoowroomLink } from '~/templates/nyc-showroom'

type QueryResult = {
  pageData: {
    frontmatter: {
      header: {
        title: string
        description: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      description: string
      address: {
        line1: string
        line2: string
      }
    }
    html: string
  }
  detailImage: GatsbyImageType
  detailImageMobile: GatsbyImageType
}

const ShowroomPage = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "showroom" } }) {
        frontmatter {
          headerVariant
          header {
            title
            description
            image {
              ...heroImage
            }
            imageMobile {
              ...mobileHeroImage
            }
          }
          description
          address {
            line1
            line2
          }
        }
        html
      }
      detailImage: file(relativePath: { eq: "los-angeles-showroom-page-rug-wall-desktop.jpg" }) {
        ...heroImage
      }
      detailImageMobile: file(
        relativePath: { eq: "los-angeles-showroom-page-rug-wall-mobile.jpg" }
      ) {
        ...mobileHeroImage
      }
    }
  `)

  const { detailImage, detailImageMobile } = query

  const {
    html: content,
    frontmatter: { header, description },
  } = query.pageData

  return (
    <Layout title={header.title} description={header.description}>
      <HeroImage
        images={{
          default: header.image,
          mobile: header.imageMobile,
        }}
        height="primary"
      >
        <Container
          sx={{
            textAlign: [null, 'center'],
          }}
        >
          {/* <HeadingV2
            level="1"
            sx={{
              color: 'inherit',
            }}
          >
            {header.title}
          </HeadingV2> */}
          <Container
            sx={{
              maxWidth: 576,
            }}
          >
            <Text
              variant="regular"
              sx={{
                color: 'inherit',
                display: ['none', 'block'],
              }}
            >
              {header.description}
            </Text>
          </Container>
        </Container>
      </HeroImage>
      <Container
        variant="containerPrimary"
        sx={{
          my: [50, null, 100],
        }}
      >
        <Text
          sx={{
            display: ['block', null, 'none'],
            mb: [theme.space.md1],
          }}
          variant="regular"
        >
          {header.description}
        </Text>
        <Box mb={[theme.space.l, null, theme.space.xl]}>
          <BaseStyles
            sx={{
              h1: {
                mb: 25,
                textTransform: `uppercase`,
                fontSize: [`17px`, null, `32px`],
              },
              '.gatsby-resp-image-wrapper': {
                my: [50, null, 100],
              },
              p: {
                color: `#666666`,
                fontSize: [`12px`, null, `16px`],
              },
              blockquote: {
                textAlign: `center`,
                mt: `40px`,
                mb: `40px`,
                a: {
                  color: '#2B2B2B',
                  textDecoration: 'none',
                  borderBottom: '1px solid #2B2B2B',
                  paddingBottom: '5px',
                  fontSize: [`10px`, null, `16px`],
                  ':visited': {
                    color: '#2B2B2B',
                  },
                },
              },
            }}
          >
            <MarkdownIt content={content} />
          </BaseStyles>
        </Box>
      </Container>
      <Container
        variant="containerPrimary"
        sx={{
          my: [50, null, 100],
        }}
      >
        {description ? (
          <Box mb={[theme.space.l, null, theme.space.xl]}>
            <BaseStyles
              sx={{
                '.gatsby-resp-image-wrapper': {
                  my: [50, null, 100],
                },
              }}
            >
              <Text
                variant="regular"
                sx={{
                  color: 'inherit',
                  display: ['none', 'block'],
                }}
              >
                {description}
              </Text>
            </BaseStyles>
          </Box>
        ) : null}
        <Box sx={{ textAlign: 'center', mb: [theme.space.l, null, theme.space.xl] }}>
          <ShoowroomLink href="https://my.matterport.com/show/?m=LeMaXcE5s4U">
            VISIT THE VIRTUAL SHOWROOM
          </ShoowroomLink>
        </Box>
        <Box sx={{ mb: [theme.space.l, null, theme.space.xl] }}>
          <LaShowroomOpenings altLayout={true} />
        </Box>
        <Box sx={{ textAlign: 'center', mb: [theme.space.l, null, theme.space.xl] }}>
          <ShoowroomLink href="/pages/nyc-showroom">VISIT THE NEW YORK SHOWROOM</ShoowroomLink>
        </Box>
      </Container>
      <HomepageHeader
        images={{
          default: detailImage,
          mobile: detailImageMobile,
        }}
        infoContainerSx={{
          sx: {
            marginX: [null, null, '50px'],
            marginLeft: ['15px', null, null],
            textAlign: [null, 'left'],
          },
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Ben Soleimani",
  "image": "https://bensoleimani.com/static/14f2622e82f12d0f50ad460652bebc44/ab8d3/homepage-showroom-desktop.webp",
  "@id": "https://bensoleimani.com/pages/showroom#la",
  "url": "https://bensoleimani.com/pages/showroom",
  "description": "Our New Los Angeles Flagship showroom is prominently located in the heart of the West Hollywood Design District (directly across from the Pacific Design Center).The clean-lined modernist building features floor-to-ceiling wrap-around windows, which allow an abundance of natural light throughout the expansive 20,000 square ft. open floor plan. Soaring 18 ft. high ceilings span two entire floors of luxury storefront.",
  "telephone": "(323) 486-3491",
  "sameAs": [
    "https://www.facebook.com/bensoleimanihome",
    "https://www.instagram.com/bensoleimani",
    "https://www.youtube.com/@bensoleimani3278",
    "https://www.linkedin.com/company/ben-soleimani"
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "8650 Melrose Avenue",
    "addressLocality": "West Hollywood",
    "addressRegion": "CA",
    "postalCode": "90069",
    "addressCountry": "US"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 34.0814176,
    "longitude": -118.3816068
  },
  "openingHoursSpecification": [{
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "09:00",
    "closes": "18:00"
  },{
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Saturday",
      "Sunday"
    ],
    "opens": "10:00",
    "closes": "16:00"
  }],
  "priceRange": "$$$",
  "currenciesAccepted": "USD",
  "paymentAccepted": ["Credit Card", "Cash"],
  "hasMap": "https://www.google.com/maps/place/Ben+Soleimani/@34.0814176,-118.3816068,15z/data=!4m6!3m5!1s0x80c2bf57c4a576c1:0x769fcf7a6c497c73!8m2!3d34.0814176!4d-118.3816068!16s%2Fg%2F11fp7tdqh2?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D",
  "isAccessibleForFree": "true",
  "amenityFeature": [
    {
      "@type": "LocationFeatureSpecification",      "name": "Wheelchair Accessible",
      "value": "true"
    },
    {
      "@type": "LocationFeatureSpecification",
      "name": "Wheelchair Accessible Parking Lot",
      "value": "true"
    }
  ],
  "founder": {
    "@type": "Person",
    "name": "Ben Soleimani",
    "sameAs": "https://bensoleimani.com/pages/about-ben"
  }
}
    `,
        }}
      />
    </Layout>
  )
}

export default ShowroomPage
